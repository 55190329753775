import { observer } from "mobx-react";
import Moralis from "moralis";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from "./index.module.scss";
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const Info = observer(() => {
    const { t } = useTranslation();
    const [holders, setHolders] = useState(0);
    const [marketCap, setMarketCap] = useState(0);
    const serverUrl = "https://6wxru4sjbgmm.usemoralis.com:2053/server";
    const appId = "o6xyZnvcNK1g7Wu5I4vKaVgVlEWCFUYTh9tQhq8D";

    const fetchData = async () => {
        try {
            Moralis.start({ serverUrl, appId });
            const options = {
                address: "0xd2d7289db68395593d65101753fec9450ddfb699",
                chain: "bsc",
                exchange: "PancakeSwapv2"
            };
            const price = await ((await Moralis.Web3API.token.getTokenPrice(options)).usdPrice * 1000000000).toFixed(2);
            setMarketCap(price);
            fetch("https://api.covalenthq.com/v1/56/tokens/0xd2d7289db68395593d65101753fec9450ddfb699/token_holders/?quote-currency=USD&format=JSON&block-height=&page-number=&page-size=100000&key=ckey_8ef7f58cf2de484e81a07db7f23")
                .then(response => response.json())
                .then(data => setHolders(data.data.items.length));
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchData();
        setInterval(() => {
            fetchData();
        }, 30000)
    }, [])

    return (
        <div id="about" className={styles["info"]}>
            <div className={styles["info__col"]}>
                <div data-aos="fade-right" data-aos-delay="50" className={styles["info__col-sticker1"]}>
                    <img alt="Ass" className={styles[""]} src={"images/ass.png"} />
                    <div className={styles["info__text-wrapper"]}>
                        <span>MarketCap</span>
                        <span className={styles["info__text-wrapper--description"]}>${marketCap}M</span>
                    </div>
                </div>
                <div data-aos="fade-down" data-aos-delay="50" className={styles["info__col-sticker2"]}>
                    <img alt="Boobs" className={styles[""]} src={"images/boobs.png"} />
                    <div className={styles["info__text-wrapper"]}>
                        <span>Holders</span>
                        <span className={styles["info__text-wrapper--description"]}>{holders}</span>
                    </div>
                </div>
                <div data-aos="fade-up" data-aos-delay="50" className={styles["info__col-sticker3"]}>
                    <img alt="Condoms" className={styles[""]} src={"images/condoms.png"} />
                    <div className={styles["info__col-sticker3-text"]}>
                        <span>Squirt responsibly!</span>
                        <span className={styles["info__col-sticker3-text--description"]}>Squirt token succesfully passed TechRate audit. Read Audit</span>
                    </div>
                    <a href="https://github.com/TechRate/Smart-Contract-Audits/blob/main/November/Squirt%20Game%20Full%20Smart%20Contract%20Security%20Audit.pdf">
                        <img alt="Techrate audit" className={styles["info__col-sticker3-techrate"]} src={"images/audit.png"} />
                    </a>
                </div>
            </div>
            <div data-aos="fade-left" data-aos-delay="50" className={styles["info__col"]}>
                <div className={styles["info__twitter"]}>
                    <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="squirtgametoken"
                        options={{ height: 280 }}
                    />
                </div>
            </div>
        </div>
    )
});

export default Info;
