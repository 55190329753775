import { observer } from "mobx-react";
import React, { useEffect } from 'react';
import Header from "../../components/header";
import Info from "../../components/info";
import Roadmap from "../../components/roadmap";
import Stickers from "../../components/stickers";
import Tokenomics from "../../components/tokenomics";
import { useStores } from "../../stores/RootStore";
import { default as commonStyles } from "./index.module.scss";

const Home = observer(() => {
    const { uiStore } = useStores();
    const { setHomeMounted } = uiStore;

    useEffect(() => {
        setHomeMounted(true);
        return () => { setHomeMounted(false); };
    });

    return (
        <div className={commonStyles["header-background"]}>
            <div className={commonStyles["container"]}>
                <Header />
                <Info />
                <Stickers />
                <Tokenomics />
                <Roadmap />
            </div>
        </div>
    )
});

export default Home;
