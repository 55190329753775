import Torus from "@toruslabs/torus-embed";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import { action, makeObservable, observable } from "mobx";
import Web3Modal from "web3modal";
import configData from "../constants/config-data.json";
import NotificationTypeEnum from "../enums/notification-type-enum";

export class ChainStore {
  provider = {};
  address = configData.address;
  abi = configData.abi;
  defaultProvider = new ethers.getDefaultProvider(configData.rpcUrl);
  defaultContract = new ethers.Contract(this.address, this.abi, this.defaultProvider);
  walletAddress = '';
  walletBalance = 0;
  chainId = 0;

  constructor(rootStore) {
    makeObservable(this, {
      //observable state
      provider: observable,
      defaultProvider: observable,
      walletAddress: observable,
      walletBalance: observable,
      chainId: observable,

      //actions
      connectToWallet: action,
      setProvider: action,
      setWalletAddress: action,
      setWalletBalance: action,
      setChainId: action,
    });
    this.uiStoreRef = rootStore.uiStore;
  }

  setProvider = (provider) => {
    this.provider = provider;
  }

  setWalletAddress = (address) => {
    this.walletAddress = address;
  }

  setWalletBalance = (balance) => {
    this.walletBalance = balance;
  }

  setChainId = (id) => {
    this.chainId = id;
  }

  connectToWallet = async () => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: "INFURA_ID" // required
        }
      },
      torus: {
        package: Torus, // required
        options: {
          networkParams: {
            host: "https://bsc-dataseed.binance.org/", // optional
            chainId: 56, // optional
            networkId: 56 // optional
          },
          config: {
            buildEnv: "development" // optional
          }
        }
      }
    };

    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions, // required
      theme: {
        background: "rgb(39, 49, 56)",
        main: "rgb(199, 199, 199)",
        secondary: "rgb(136, 136, 136)",
        border: "rgba(195, 195, 195, 0.14)",
        hover: "rgb(16, 26, 32)"
      }
    });
    // Prompt user for account connections
    const provider = await web3Modal.connect();

    this.setProvider(new ethers.providers.Web3Provider(provider, "any"));

    await this.provider.send("eth_requestAccounts", []);
    const signer = this.provider.getSigner();
    await signer.getAddress();

    if (this.provider.network !== configData.chainId) {
      const params = [{
        chainId: configData.chainIdHex,
        chainName: configData.chainName,
        nativeCurrency: {
          name: configData.currency.name,
          symbol: configData.currency.symbol,
          decimals: configData.currency.decimals
        },
        rpcUrls: [configData.rpcUrl],
        blockExplorerUrls: [configData.blockExplorerUrl]
      }]
      window.ethereum.request({ method: 'wallet_addEthereumChain', params })
        .then(
          async () => {
            this.setUserDetails();
          })
        .catch(error => console.log("Error", error.message))
    } else {
      this.setUserDetails();
    }
  };

  showNotification = (condition, notificationMessage, notificationType) => {
    if (this.uiStoreRef) {
      if (condition) {
        this.uiStoreRef.setNotificationMessage(
          notificationMessage,
          notificationType
        )
      } else {
        const resetValue = notificationType === NotificationTypeEnum.ERROR
        this.uiStoreRef.setNotificationBarVisibility(resetValue)
        this.uiStoreRef.setNotificationMessage(
          notificationMessage,
          notificationType
        )
      }
    }
  }

  setUserDetails = async () => {
    await this.provider.send("eth_requestAccounts", []);
    const signer = this.provider.getSigner();
    this.setWalletAddress(await signer.getAddress());
    this.setChainId(parseInt(this.provider.network.chainId));
    this.setWalletBalance(await this.provider.getBalance(this.walletAddress));
    if (this.provider.network.chainId === configData.chainId) {
      this.uiStoreRef.setIsCorrectWallet(true);
      this.showNotification(
        !this.uiStoreRef.notificationBar.isVisible,
        "Successfully connected to BSC with: ..." + this.walletAddress.slice(-8),
        NotificationTypeEnum.SUCCESS
      );
    } else {
      this.uiStoreRef.setIsCorrectWallet(false);
      this.showNotification(
        !this.uiStoreRef.notificationBar.isVisible,
        "Wrong Chain ID, connect to BSC Smart Chain Mainnet!",
        NotificationTypeEnum.ERROR
      )
    }
  }
}

export default ChainStore;
