import { observer } from "mobx-react";
import React, { useState, useEffect } from 'react';
import { useStores } from "../../stores/RootStore";
import { default as styles } from "./index.module.scss";

const Timer = observer((dateFrom) => {
    let days;
    let hours;
    let minutes;
    let seconds;
    let distance;
    const calcDiffInMinutes = (dateA, dateB) => {
        distance = (new Date(dateB.dateFrom).getTime() - dateA.getTime());
        days = Math.floor(distance / (1000 * 60 * 60 * 24));
        hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        seconds = Math.floor((distance % (1000 * 60)) / 1000);
    }
    const [currentDate, setCurrentDate] = useState(new Date());
    const [minutesDiff, setMinutesDiff] = useState(
        calcDiffInMinutes(currentDate, dateFrom)
    );
    const { uiStore } = useStores();
    const { hideTimer, setHideTimer } = uiStore;

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setCurrentDate(new Date());
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [currentDate]);

    useEffect(() => {
        setMinutesDiff(calcDiffInMinutes(currentDate, dateFrom));
        if (hours < 1 && minutes < 1 && seconds < 1) {
            setHideTimer(true)
        }
    }, [currentDate, dateFrom, minutesDiff]);

    return (
        hideTimer ?
            null
            :
            <div className={styles["launch"]} >
                <div className={styles["launch-title"]}>
                    Launch in :
                    <div className={styles["launch-date"]}>
                        <div className={styles["launch-numbers-days"]}>
                            {days}
                        </div>
                        d
                        <div className={styles["launch-numbers"]}>
                            {hours}
                        </div>
                        h
                        <div className={styles["launch-numbers"]}>
                            {minutes}
                        </div>
                        m
                        <div className={styles["launch-numbers"]}>
                            {seconds}
                        </div>
                        s
                    </div>
                </div>
            </div>
    )
});

export default Timer;