export const TRANSLATIONS_FR = {
    navbar: {
        home: "ACCUEIL",
        description: "À PROPOS DE",
        buy_nfts: "ACHAT",
        faq: "FAQ",
        browse_squirtgames: "BROWSE",
        menu: "MENU",
    },
    header: {
        title: "Que sont les Squirt Games ?",
        description: "10000 Squirt Games générés de manière programmatique ont été créés pour habiter la Smart Chain de Binance. Chaque Squirt Game est un hommage aux années 90 et aux personnes/mèmes aimés par la communauté au sein de la scène des crypto-monnaies. Les Squirt Games offrent également des récompenses potentielles à la communauté et aux organisations caritatives, comme on peut le voir ci-dessous.",
        tv_text: ">>> CLIQUEZ-MOI <<<"
    },
    nft_description: {
        reward_title: "Système de récompense communautaire",
        reward_description: "Afin d'encourager la création d'une communauté autour du Squirt Game, un système de récompense peut être mis en place. Si l'offre totale de Squirt Games (10000) a été vendue dans les 6 mois après le lancement de la vente, le contrat intelligent a une fonction intégrée qui permet alors à quiconque de lancer un système de récompense aléatoire à partir des fonds collectés par la vente de Squirt Games. 1000 BNB sont donnés au propriétaire d'un Squirt Game ID aléatoire, et 1000 BNB supplémentaires sont répartis entre cent Squirt Game ID aléatoires et leurs propriétaires respectifs (10 BNB chacun). Pour garantir une randomisation entièrement vérifiable et l'équité des identifiants sélectionnés comme gagnants, Squirt Game a travaillé avec Chainlink pour utiliser leur Oracle VRF. Un total de 300 BNB a également été alloué pour être donné à une œuvre de charité, comme voté par la communauté. Lors du vote, chaque Squirt Game détenu par une adresse donne droit à une voix. Dans le cas où les 10000 NFT n'auraient pas été vendus dans les 6 mois suivant le début de la vente, les fonds sont donnés à l'équipe. Tout fonds non réclamé restant sur le contrat 6 mois après le début de la vente sera également réattribué à l'équipe.",
        purchase_title: "Comment puis-je acheter des Squirt Games ?",
        purchase_description_1: "Le moyen le plus simple d'acquérir votre propre Squirt Game est de l'acheter ci-dessous. Vous pouvez également les échanger sur n'importe quelle place de marché NFT supportant les Squirt Games. Le prix d'achat suit une courbe d'adhésion qui récompense les supporters précoces, et 75% des NFTs coûtent moins de 0,5 BNB tandis que le reste coûte un maximum de 0,7 BNB.",
        purchase_description_1_prices:
            [
                "0 - 249: Réservé au marketing, à l'aide et aux récompenses de la communauté",
                "250 - 3499: 0.3 BNB",
                "3500 - 5499: 0.4 BNB",
                "5500 - 7499: 0.5 BNB",
                "7500 - 8999: 0.6 BNB",
                "9000 - 9999: 0.7 BNB"
            ],
        purchase_description_2: "Pour lancer l'achat, vous devez d'abord connecter un portefeuille qui a été rechargé avec BNB. Une fois l'achat effectué, le NFT est automatiquement envoyé sur votre porte-monnaie et vous pouvez le consulter sous l'onglet Parcourir.",
        interacting_title: "",
        interacting_description: "",
    },
    purchase_nfts: {
        purchase_squirtgame: "Acheter des Squirt Games",
        sold: "VENDU",
        balance: "Solde:",
        connect_to_wallet: "SE CONNECTER AU PORTEFEUILLE",
        purchase_squirtgames: "ACHETER DES NFTENDOS",
        wrong_chain_id: "CONNECTÉ À LA MAUVAISE CHAÎNE",
        accept_tos: "VOUS DEVEZ ACCEPTER NOS CONDITIONS",
        disclamer: "En achetant des Squirt Games, vous reconnaissez que vous n'achetez qu'un NFT. Les éventuelles récompenses communautaires dépendent de l'épuisement du stock total de Squirt Games dans les 6 mois suivant le début de la vente. Si le stock total de Squirt Games n'a pas été vendu dans les 6 mois suivant le début de la vente, aucune récompense communautaire ne sera attribuée."
    },
    range_slider: {
        title: "Quantité de Squirt Games à acheter",
        total_cost: "Coût total"
    },
    nft_search_bar: {
        find_squirtgames: "Trouver les Squirt Games:",
        placeholder: "IDs séparés par une virgule, par exemple : 1,2,3",
        show_nfts: "AFFICHER MES NFTS",
        search_nfts: "RECHERCHER DES NFTS"
    },
    faq: {
        title: "QUESTIONS FRÉQUEMMENT POSÉES",
        items: [
            { question: "Le système de récompense de la communauté est-il entièrement aléatoire ?", answer: "Oui. Nous avons travaillé avec Chainlink et sa solution VRF qui fournit un caractère aléatoire inviolable qui ne peut être manipulé par aucun utilisateur, opérateur de nœud ou acteur malveillant, contrairement à l'utilisation de block.time qui pourrait être manipulé: https://chain.link/solutions/chainlink-vrf" },
            { question: "Quel est l'enregistrement de provance pour Squirt Game ?", answer: "Chaque Squirt Game a été haché à l'aide de SHA-256, et tous les hachages sont ensuite combinés en une longue chaîne qui est à nouveau hachée à l'aide de SHA-256 et stockée sur la blockchain à l'intérieur du contrat intelligent Squirt Game." },
            { question: "La valeur de mes Squirt Games va-t-elle augmenter ?", answer: "Les Squirt Games sont des NFT conçus comme des cartes à collectionner sur la Smart Chain de Binance. Comme pour toute autre œuvre d'art, le prix dépend entièrement de ce que les gens sont prêts à payer, et les cartes que vous appréciez le plus peuvent avoir une valeur nulle pour d'autres. Comme pour l'achat de toute œuvre d'art, vous ne pouvez pas savoir si le prix va rester stable, augmenter ou avoir une valeur nulle." },
            { question: "Puis-je, à l'avance, savoir quel Squirt Game je vais recevoir ?", answer: "Avant qu'un Squirt Game ne soit frappé, toutes les informations, à l'exception du hash SHA-256 appartenant à l'image, ne sont pas révélées. Après un achat, le référentiel Squirt Game est automatiquement mis à jour avec l'image et les statistiques correspondantes en quelques minutes." },
            { question: "Que se passe-t-il si les 10000 Squirt Games n'ont pas tous été vendus dans les 6 mois ?", answer: "Dans le cas où tous les Squirt Games n'ont pas été vendus dans les 6 mois, la récompense communautaire est annulée et tous les fonds collectés vont à l'équipe." },
            { question: "Est-ce que Squirt Game ne recevra rien si les 10000 Squirt Games ont été vendus dans les 6 mois ?", answer: "Une fois que la réserve de récompenses de la communauté (2000 BNB) a été remplie, Squirt Game peut retirer les fonds excédentaires. Par exemple, s'il y a 2100 BNB sur le contrat, Squirt Game peut retirer un maximum de 100 BNB pendant les 6 premiers mois. S'il y a 1900 BNB, Squirt Game ne peut retirer aucun fonds du contrat au cours des 6 premiers mois. Si les 10000 Squirt Games n'ont pas été vendus dans les 6 mois, le système de récompense de la communauté a échoué, ce qui signifie qu'aucune récompense de la communauté n'est versée et que Squirt Game peut retirer tous les fonds actuellement sur le contrat." },
            { question: "Comment puis-je interagir avec mes Squirt Games ?", answer: "Vous pouvez consulter vos Squirt Games sur ce site web et sur n'importe quel porte-monnaie avec support, et ils sont négociables sur les marchés NFT ouverts supportant la norme BEP721." },
            { question: "J'ai gagné ! Comment puis-je réclamer ma récompense communautaire ?", answer: "Vous pouvez réclamer votre récompense communautaire sur le site Web dans les 6 mois suivant le début de la vente, si le programme de récompense communautaire a été couronné de succès. Pour récupérer votre récompense communautaire, saisissez le numéro de l'emplacement et appuyez sur le bouton Réclamer la récompense. Pour que la réclamation soit réussie, l'adresse du gagnant et l'adresse à partir de laquelle vous effectuez la transaction doivent correspondre." },
            { question: "Que se passe-t-il s'il y a des récompenses communautaires non réclamées après six mois à compter du début de la vente ?", answer: "Tout fonds non réclamé laissé sur le contrat 6 mois après le début de la vente appartiendra également à l'équipe." },
            { question: "Quel est le code ?", answer: "Haut, haut, bas, bas, gauche, droite, gauche, droite, B, A." }
        ]
    }
};
