export const TRANSLATIONS_ZH = {
    navbar: {
        menu: "菜单",
        about: "关于",
        tokenomics: "代币经济学",
        team: "团队",
        roadmap: "路线图",
        trade: "贸易",
        contract: "合同",
        connect: "连接"
    },
    header: {
        title: "No rug... all tug",
        description: "SQUIRT是一种防地毯式的去中心化BSC备忘录代币，会让你湿身。",
        tokenTitle: "用于BSC链的SQUIRT代币",
        tokenButton: "加入预售活动",
        tokenDescription: "7%的滑坡率",
    },
    stickers: {
        mcap: "市值",
        holders: "持有者",
        transactions: "交易",
        burnedTokens: "烧毁的信物",
        feature1: {
            title: "这里没有地毯!",
            description: "预售的BNB筹集的80%进入煎饼流动资金池，而LP则被烧毁!"
        },
        feature2: {
            title: "Squirters 获取 Squirted!",
            description: "我们向持有SQUIRT的人赠送$SQUIRT代币，因为我们真他妈的了不起"
        },
        feature3: {
            title: "色情明星大使!",
            description: "在与世界上一些最热门的色情明星的讨论中，他们喷出了!"
        },
        feature4: {
            title: "社区驱动!",
            description: "这个项目的未来的关键决定由#SquirtSquad的投票决定!"
        },
    },
    purchase: {
        connect: "连接",
        balance: "平衡",
        title: "现在购买",
        button: "购买"
    },
    socials: {
        title: "社交活动",
        close: "关闭"
    },
    tokenomics: {
        title: "代币经济学",
        block1: "供应总量: 1,000,000,000,000,000 SQUIRT",
        block2: "40%用于预售（未售出的代币被烧毁）。",
        block3: "25.6%用于匹配BNB筹集的资金（剩余部分烧掉）。",
        block4: "预售价格为每1,000,000,000 SQUIRT 0.0016 BNB。",
        block5: "在20周内释放10%的团队资金",
        block6: "10%的赠品，营销，影响者，NFT的",
        block7: "BNB筹集到的80%的资金被添加到Pancake LP中。",
        block8: "LP 代币被烧毁，筹集的资金用于多重签名",
        block9_1: "7% 交易费",
        block9_2: "3% 的收益反映给 SQUIRT 持有者，2% 的流动资金池，2% 的营销和发展基金",
    },
    team: {
        title: "团队",
        members: [
            {
                image: "images/team/peter.svg",
                name: "Peter - 全栈开发人员",
                quote: "谁知道按正确的顺序按键，能让世界变得更湿润？",
                description: "从我还是个小孩子的时候起，我就对使用软件解决实际问题的想法非常着迷。我坚信编程的力量可以改变和改善世界各地人们的生活。"
            },
            {
                image: "images/team/hato.svg",
                name: "Hato - 首席设计师",
                quote: "你所想象的一切是湿的",
                description: "经过14年的品牌建设，设计产品线和游戏项目，主要是为自己创造利润，我很高兴，现在我可以利用我的经验建立一个遗产，由于艺术和技术的独特联系，使世界变得更好。这有多令人兴奋？"
            },
            {
                image: "images/team/dan.svg",
                name: "Dan - 首席营销官",
                quote: "在这里等着，我去拿你的伟哥",
                description: "从我还是个小孩子的时候起，我就对使用软件解决实际问题的想法非常着迷。我坚信编程的力量可以改变和改善世界各地人们的生活。"
            },
            {
                image: "images/team/loominc.svg",
                name: "loominc - 社区经理",
                quote: "上有所好，下必甚焉",
                description: "从我还是个小孩子的时候起，我就对使用软件解决实际问题的想法非常着迷。我坚信编程的力量可以改变和改善世界各地人们的生活。"
            },
            {
                image: "images/team/dex.svg",
                name: "Dex - 产品经理",
                quote: "当我们作为一个整体工作时，一切皆有可能。",
                description: "让游戏开始吧。"
            },
            {
                image: "images/team/yescubanb.svg",
                name: "YesCubanB - 销售经理",
                quote: "不要忘记你的雨伞",
                description: "从我还是个小孩子的时候起，我就对使用软件解决实际问题的想法非常着迷。我坚信编程的力量可以改变和改善世界各地人们的生活。"
            },
            {
                image: "images/team/jeff.svg",
                name: "Jeff - Solidity开发者",
                quote: "!false, 因为是真实的，所以有趣",
                description: "从我还是个小孩子的时候起，我就对使用软件解决实际问题的想法非常着迷。我坚信编程的力量可以改变和改善世界各地人们的生活。"
            },
            {
                image: "images/team/crypdon.svg",
                name: "Crypdon",
                quote: "当我们与你的女朋友合作时，发现她有OnlyFans",
                description: "业务发展经理"
            },
        ]
    },
    roadmap: {
        title: "ROADMAP",
        button1: "View Medium",
        button2: "Download PDF",
        blocks: [
            {
                title: "MARKETING",
                description: ["AMA (bi-weekly)", "Cameos(Adult stars, OnlyFans, eccentric celebrities)", "Promotional tweets", "Website banners", "Community medium articles", "Listing on CMC & CoinGecko", "Banner ads on erotic and meme related sites, pr articles", "Partnership"],
            },
            {
                title: "COMMUNITY CARE",
                description: ["Form the Squirt Army!", "Discord discussions", "Meme Contests", "Sticker Contests", "Rewards for community supporter"],
            },
            {
                title: "NFT’s",
                description: ["Form the Squirt Army!"
                    , "Discord discussions"
                    , "Meme Contests"
                    , "Sticker Contests"
                    , "Rewards for community supporter"],
            },
            {
                title: "People of SQUIRTOPIA",
                description: ["Autogenerated NFTs with random rarity based on cumulative power of each NFT layer"
                    , "Utilized NFTS:"
                    , "- Stake in DAO to acces DAO powers"
                    , "- Stake up to 5 NFTs and earn Squirtswap, Games, NFT sales and other ecosystem profit share based on combine staking power of your NFTs"],
            },
            {
                title: "SQUIRTGAMES",
                description: ["On chain games SQUIRT17, “Squirt & Burny” and more"
                    , "Spend and win $SQUIRT token"
                    , "Share of games profit for NFT stakers"],
            },
            {
                title: "SQUIRTDAO",
                description: ["Home of all hardcore Squirters"
                    , "Share your ideas. Vote in important matters and earn"
                    , "from Ecosystem profit"],
            },
            {
                title: "SQUIRTSWAP",
                description: ["The wettest swap platform where liquidity never dries!"
                    , "Squirt Squad will buy back and burn SQUIRT"
                    , "Share profit with DAO NFT stakers"],
            },
            {
                title: "SQUIRTSHOP",
                description: ["Branded merch"
                    , "Sex toys, adult themed items"
                    , "Profit share with SQUIRT relfection"],
            },
            {
                title: "SQUIRTBRIDGE (CROSSCHAIN)",
                description: ["CRONOS"
                    , "AVAX"
                    , "POLYGON"
                    , "MOONRIVER"
                    , "XDAI"
                    , "and more..."],
            },
            {
                title: "SQUIRTFLIX",
                description: ["Sponsoring and funding adult content"
                    , "VR/AR/Metaverse cross-markets"
                    , "Subscription using SQUIRTPAY"],
            },
            {
                title: "ADULT STARS NFT COLLAB",
                description: [],
            },
            {
                title: "CEX LISTINGS",
                description: [],
            },
        ],
    },
    faq: {
        title: "FREQUENTLY ASKED QUESTIONS",
        items: [
            {
                question: "What is Squirt?",
                answer: "Squirt is a part-meme, part adult-themed decentralized token. The SQUIRT token will allow holders to upload and trade SQUIRT NFT assets, play games, access exclusive content, live shows, videos, VR, AR and adult metaverse content. SQUIRT will allow adult industry content creators a solution to monetize their work, all whilst maintaining their privacy and anonymity. The Squirt team have high ambitions to cement the SQUIRT token as the leading crypto token associated with the adult entertainment industry, by developing an on-ramping token access payment solution known as SQUIRTSWAP, which will provide users the opportunity to both exchange crypto and altcoins into SQUIRT or purchase them directly using traditional FIAT payment methods. Similarly, we are developing an off-ramping solution to allow content creators and performers from leading platforms such as OnlyFans, to exchange their SQUIRT tokens back into FIAT. In addition to this, we are developing a cross-chain bridging solution for other EVM combatable chains (SQUIRTBRIDGE)."
            },
            {
                question: "What is the contract address?",
                answer: "The BEP20 token address is 0xd2d7289DB68395593D65101753Fec9450ddFB699 (https://bscscan.com/address/0xd2d7289DB68395593D65101753Fec9450ddFB699) (always look for the address ending in 699!)."
            },
            {
                question: "What is the total supply?",
                answer: "1,000,000,000,000,000 SQUIRT tokens were minted – No more will ever be created."
            },
            {
                question: "Is there a pre-sale?",
                answer: "The pre-sale commenced on the Pinksale Finance platform on Tuesday 16th November. The presale was set to run for 7-days, however all SQUIRT tokens sold out in less than 24 hours, reaching a hardcap of 640 BNB. For more details, see here: https://www.pinksale.finance/#/launchpad/0xd67B8fCA18E6347fE616fd1e88e7F1fe54Fe87bb?chain=BSC ."
            },
            {
                question: "What was the pre-sale rate?",
                answer: "Pre-sale investors were able to purchase at a rate of 1 BNB = 625,000,000,000 SQUIRT."
            },
            {
                question: "Were pre-sale tokens ‘vested’?",
                answer: "Yes, both for presale investors and the team themselves. Presale investors could claim 25% of their tokens immediately after the pre-sale ended, followed by a further 25% of tokens every 7 days. The team can claim 5% of tokens every 7 days, followed by a further 5% of tokens every 7 days."
            },
            {
                question: "Where can I get squirt?",
                answer: "Squirt can be obtained via PancakeSwap (https://pancakeswap.finance/swap?outputCurrency=0xd2d7289DB68395593D65101753Fec9450ddFB699) DEX or a suitable DEX aggregator service such as 1Inch. We also plan to launch the SQUIRT token on multiple CEX exchanges very soon."
            },
            {
                question: "Are there any benefits to holding (HODL) SQUIRT tokens?",
                answer: "SQUIRT token holders receive yield reflection fees on every buy and sell trade made through Pancake Swap. This is possible due to the 7% transaction tax imposed on buyers and sellers. This 7% tax is distributed as follows: 3% is spilt amongst SQUIRT token holders, 2% goes into the Pancake Swap liquidity pool to gradually increase the price floor of the asset. 2% goes into the team marketing and promotion wallet to allow us to continue to develop the brand, provide liquidity to other exchanges, offer giveaways, and incentivize social media influencers to continue to drive adoption of our products."
            },
            {
                question: "How is this project “rug-proof”?",
                answer: "Please read our detailed Medium article for more information on this topic: https://squirtgame.medium.com/why-squirt-game-is-rug-proof-69555b35874c ."
            },
            {
                question: "How long is the liquidity locked in for the Pancake Swap liquidity pool?",
                answer: "1095 days – Please view the link to verify this: https://www.pinksale.finance/#/pinklock/record/2671?chain=BSC"
            },
            {
                question: "What is a multisig wallet?",
                answer: "Multisig is short for “multi-signature”. A multisig wallet is a special wallet that will only allow withdrawals and transfers of tokens and coins from it, if all parties that form the wallet, have signed the transaction with their own keys. This is a security measure to ensure that no single team member has the power to make transactions with team funds, unless expressly agreed by the team members."
            },
            {
                question: "How many members of the team are there?",
                answer: "Currently we have 8 team members, although we expect this to grow over time."
            },
            {
                question: "Have you been audited?",
                answer: "We are currently undergoing a full audit review from Techrate. Results of the audit will be published before the end of November."
            },
            {
                question: "How many token holders are there?",
                answer: "This information can be viewed here: https://bscscan.com/token/0xd2d7289DB68395593D65101753Fec9450ddFB699#balances ."
            },
            {
                question: "Where are you listed?",
                answer: "We are already listed on CoinMarketCap (https://coinmarketcap.com/currencies/squirt-game/), and expect to be listed on CoinGecko very shortly."
            },
            {
                question: "Are you listed on any exchanges?",
                answer: "We will be making an announcement on this subject very shortly!"
            },
            {
                question: "What is the code?",
                answer: "UP UP DOWN DOWN LEFT RIGHT LEFT RIGHT B A"
            },
        ]
    }
};