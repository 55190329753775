import { observer } from "mobx-react";
import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import ButtonTypeEnum from "../../enums/button-type-enum";
import ScrollLinkEnum from "../../enums/scroll-link-enum";
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const Button = observer((props) => {
    const { uiStore, chainStore } = useStores();
    const { hamburgerMenuOpen, switchHamburgerMenu, scrollLink, setScrollLink } = uiStore;
    const [animateButton, setAnimateButton] = useState(false);

    const getAnimateButton = () => {
        setTimeout(() => {
            setAnimateButton(true);
        }, 0);
        setAnimateButton(false);
    }

    return (
        props.type === ButtonTypeEnum.NAVLINK ?
            props.type === ButtonTypeEnum.NAVLINK &&
            <NavLink
                onClick={() => getAnimateButton()} className={styles["button"]} to={props.path} activeClassName={props.path.includes(scrollLink) && styles["button--active"]} >
                <div className={styles[animateButton && "button--animate"]}>{props.label}</div>
            </NavLink >
            :
            props.type === ButtonTypeEnum.LINK1 &&
            <a
                onClick={() => getAnimateButton()} className={styles["button"]} href={props.path}  >
                <div className={styles[animateButton && "button--animate"]}>{props.label}</div>
            </a >
    )
});

export default Button;