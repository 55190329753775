import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import styles from "./index.module.scss";

const Tokenomics = observer(() => {
    const { t } = useTranslation();

    return (
        <div id="tokenomics" className={styles["tokenomics"]}>
            <div className={styles["tokenomics__background"]} />
            <div className={styles["tokenomics__wrapper"]}>
                <div data-aos="fade-right" className={styles["tokenomics__wrapper-title"]}>
                    <h2>
                        Tokenomics
                    </h2>
                    <span>Total Supply:  1,000,000,000,000,000 SQUIRT</span>
                </div>
            </div>

            <div data-aos="fade-left" data-aos-delay="300" className={styles["tokenomics__sticker"]}>
                <div className={styles["tokenomics__sticker-text"]}>
                    <span className={styles["tokenomics__sticker-title"]}>7% Transaction Fee</span>
                    <div className={styles["tokenomics__sticker-description"]}>
                        <span>3% yield reflection to SQUIRT holders</span>
                        <span>2% locked liquidity pool</span>
                        <span>2% marketing and development fund</span>
                    </div>
                </div>
            </div>
            <div className={styles["tokenomics__bottom-wrapper"]}>
                <img data-aos="fade-right" data-aos-delay="600" className={styles["tokenomics__diagram"]} src={"images/tokenomics.png"} alt="Tookenomics" />
                <div data-aos="fade-left" data-aos-delay="900" className={styles["tokenomics__sticker-wrapper"]}>
                    <div className={styles["tokenomics__sticker"]}>
                        <div className={styles["tokenomics__sticker-text"]}>

                            <span className={styles["tokenomics__sticker-title"]}>40% Presale on Pinksale</span>
                            <div className={styles["tokenomics__sticker-description"]}>
                                <span>Sold out in 24 hrs, 640 BNB raised</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles["tokenomics__sticker"]}>
                        <div className={styles["tokenomics__sticker-text"]}>

                            <span className={styles["tokenomics__sticker-title"]}>25.6% Liquidity </span>
                            <div className={styles["tokenomics__sticker-description"]}>
                                <span>3 years Liquidity lock</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles["tokenomics__sticker"]}>
                        <div className={styles["tokenomics__sticker-text"]}>

                            <span className={styles["tokenomics__sticker-title"]}>24.4% Marketing and Development</span>
                            <div className={styles["tokenomics__sticker-description"]}>
                                <span>Stored in Multisig wallet</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles["tokenomics__sticker"]}>
                        <div className={styles["tokenomics__sticker-text"]}>

                            <span className={styles["tokenomics__sticker-title"]}>10% Team Funds</span>
                            <div className={styles["tokenomics__sticker-description"]}>
                                <span>Vested linearly over 20-week period</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
});

export default Tokenomics;
