export const TRANSLATIONS_DE = {
    navbar: {
        menu: "Menü",
        about: "Über uns",
        tokenomics: "Tokenomics",
        team: "Team",
        roadmap: "Roadmap",
        trade: "Trade",
        contract: "Contract",
        connect: "Verbinden"
    },
    header: {
        title: "No rug... all tug",
        description: "SQUIRT ist ein Rug-Proof dezentralisierter BSC Meme Token der dich nass werden lässt.",
        tokenTitle: "SQUIRT Token für BSC Chain",
        tokenButton: "AM VORVERKAUF TEILNEHMEN",
        tokenDescription: "",
    },
    stickers: {
        mcap: "Marktkapitalisierung",
        holders: "Holders",
        transactions: "Transactions",
        burnedTokens: "Burned Tokens",
        feature1: {
            title: "Kein Rug hier!",
            description: "80% der im Vorverkauf eingenommenen BNB gehen in den Pancake Liquidity Pool und die LP ist verbrannt!"
        },
        feature2: {
            title: "Squirters werden Gesquirted!",
            description: "Wir geben $SQUIRT tokens an SQUIRT Holder weil wir die Geilsten sind!"
        },
        feature3: {
            title: "Pornstar Ambassadors!",
            description: "In Gesprächen mit einigen der heißesten, squirtenden Pornstars aus der ganzen Welt!"
        },
        feature4: {
            title: "Gemeinschaftsorientiert!",
            description: "Wichtige Entscheidungen über die Zukunft dieses Projekts werden durch die Stimmen des #SquirtSquad getroffen!"
        },
    },
    purchase: {
        connect: "VERBINDEN",
        balance: "SALDO",
        title: "JETZT KAUFEN",
        button: "KAUFEN"
    },
    socials: {
        title: "SOCIALS",
        close: "SCHLIEßEN"
    },
    tokenomics: {
        title: "Tokenomics",
        block1: "Maximale Versorgung: 1,000,000,000,000,000 SQUIRT",
        block2: "40% für Vorverkauf (nicht verkaufte Tokens werden verbrannt)",
        block3: "25.6% werden eingenomennen BNB zugeteilt (Rest verbrannt)",
        block4: "Vorverkauf startet bei 0.0016 BNB pro 1,000,000,000 SQUIRT",
        block5: "10% team funds über einen Zeitraum von 20-Wochen freigegeben",
        block6: "10% giveaways, marketing, influencers, NFT’s",
        block7: "80% der eingenommenen BNB werden dem Pancake LP hinzugefügt",
        block8: "LP-Token verbrannt, gesammelte Mittel gehen an Multisig",
        block9_1: "7% Transaktionsgebühr",
        block9_2: "3% Renditereflexion für SQUIRT-Inhaber, 2% Liquiditätspool, 2% Marketing- und Entwicklungsfonds",
    },
    team: {
        title: "TEAM",
        members: [
            {
                image: "images/team/peter.svg",
                name: "Peter - Full Stack Developer",
                quote: "Who knew that pressing keys in the right order, could make the world wetter place",
                description: "Ever since I was a little kid I have been obsessed with the idea of using software to solve practical problems. I strongly believe in the power of programming to transform and improve the lives of people around the world."
            },
            {
                image: "images/team/hato.svg",
                name: "Hato - Lead Designer",
                quote: "Everything you imagine is wet",
                description: "After 14 years of building brands, designing product lines and games for projects focused primarily on generating profits for only themselves, I am happy that now i can use my experience to build a legacy which makes the world better place thanks to the unique connection of art and technology. How exciting is that?"
            },
            {
                image: "images/team/dan.svg",
                name: "Dan - Chief Marketing Officer",
                quote: "Wait here, I’ll get your viagra",
                description: "Ever since I was a little kid I have been obsessed with the idea of using software to solve practical problems. I strongly believe in the power of programming to transform and improve the lives of people around the world."
            },
            {
                image: "images/team/loominc.svg",
                name: "loominc - Community Manager",
                quote: "What goes up must come down",
                description: "Ever since I was a little kid I have been obsessed with the idea of using software to solve practical problems. I strongly believe in the power of programming to transform and improve the lives of people around the world."
            },
            {
                image: "images/team/dex.svg",
                name: "Dex - Product manager",
                quote: "Anything is possible when we work as one.",
                description: "Let the games begin."
            },
            {
                image: "images/team/yescubanb.svg",
                name: "YesCubanB - Sales Manager",
                quote: "Don’t forget your umbrella",
                description: "Ever since I was a little kid I have been obsessed with the idea of using software to solve practical problems. I strongly believe in the power of programming to transform and improve the lives of people around the world."
            },
            {
                image: "images/team/jeff.svg",
                name: "Jeff - Solidity Developer",
                quote: "Every team needs an actual legend - What can I say",
                description: "Ever since I was a little kid I have been obsessed with the idea of using software to solve practical problems. I strongly believe in the power of programming to transform and improve the lives of people around the world."
            },
            {
                image: "images/team/crypdon.svg",
                name: "Crypdon",
                quote: "Find out your girlfriend has OnlyFans when we partner up with her",
                description: "Business development manager"
            },
        ]
    },
    roadmap: {
        title: "ROADMAP",
        button1: "View Medium",
        button2: "Download PDF",
        blocks: [
            {
                title: "MARKETING",
                description: ["AMA (bi-weekly)", "Cameos(Adult stars, OnlyFans, eccentric celebrities)", "Promotional tweets", "Website banners", "Community medium articles", "Listing on CMC & CoinGecko", "Banner ads on erotic and meme related sites, pr articles", "Partnership"],
            },
            {
                title: "COMMUNITY CARE",
                description: ["Form the Squirt Army!", "Discord discussions", "Meme Contests", "Sticker Contests", "Rewards for community supporter"],
            },
            {
                title: "NFT’s",
                description: ["Form the Squirt Army!"
                    , "Discord discussions"
                    , "Meme Contests"
                    , "Sticker Contests"
                    , "Rewards for community supporter"],
            },
            {
                title: "People of SQUIRTOPIA",
                description: ["Autogenerated NFTs with random rarity based on cumulative power of each NFT layer"
                    , "Utilized NFTS:"
                    , "- Stake in DAO to acces DAO powers"
                    , "- Stake up to 5 NFTs and earn Squirtswap, Games, NFT sales and other ecosystem profit share based on combine staking power of your NFTs"],
            },
            {
                title: "SQUIRTGAMES",
                description: ["On chain games SQUIRT17, “Squirt & Burny” and more"
                    , "Spend and win $SQUIRT token"
                    , "Share of games profit for NFT stakers"],
            },
            {
                title: "SQUIRTDAO",
                description: ["Home of all hardcore Squirters"
                    , "Share your ideas. Vote in important matters and earn"
                    , "from Ecosystem profit"],
            },
            {
                title: "SQUIRTSWAP",
                description: ["The wettest swap platform where liquidity never dries!"
                    , "Squirt Squad will buy back and burn SQUIRT"
                    , "Share profit with DAO NFT stakers"],
            },
            {
                title: "SQUIRTSHOP",
                description: ["Branded merch"
                    , "Sex toys, adult themed items"
                    , "Profit share with SQUIRT relfection"],
            },
            {
                title: "SQUIRTBRIDGE (CROSSCHAIN)",
                description: ["CRONOS"
                    , "AVAX"
                    , "POLYGON"
                    , "MOONRIVER"
                    , "XDAI"
                    , "and more..."],
            },
            {
                title: "SQUIRTFLIX",
                description: ["Sponsoring and funding adult content"
                    , "VR/AR/Metaverse cross-markets"
                    , "Subscription using SQUIRTPAY"],
            },
            {
                title: "ADULT STARS NFT COLLAB",
                description: [],
            },
            {
                title: "CEX LISTINGS",
                description: [],
            },
        ],
    },
    faq: {
        title: "FREQUENTLY ASKED QUESTIONS",
        items: [
            {
                question: "What is Squirt?",
                answer: "Squirt is a part-meme, part adult-themed decentralized token. The SQUIRT token will allow holders to upload and trade SQUIRT NFT assets, play games, access exclusive content, live shows, videos, VR, AR and adult metaverse content. SQUIRT will allow adult industry content creators a solution to monetize their work, all whilst maintaining their privacy and anonymity. The Squirt team have high ambitions to cement the SQUIRT token as the leading crypto token associated with the adult entertainment industry, by developing an on-ramping token access payment solution known as SQUIRTSWAP, which will provide users the opportunity to both exchange crypto and altcoins into SQUIRT or purchase them directly using traditional FIAT payment methods. Similarly, we are developing an off-ramping solution to allow content creators and performers from leading platforms such as OnlyFans, to exchange their SQUIRT tokens back into FIAT. In addition to this, we are developing a cross-chain bridging solution for other EVM combatable chains (SQUIRTBRIDGE)."
            },
            {
                question: "What is the contract address?",
                answer: "The BEP20 token address is 0xd2d7289DB68395593D65101753Fec9450ddFB699 (https://bscscan.com/address/0xd2d7289DB68395593D65101753Fec9450ddFB699) (always look for the address ending in 699!)."
            },
            {
                question: "What is the total supply?",
                answer: "1,000,000,000,000,000 SQUIRT tokens were minted – No more will ever be created."
            },
            {
                question: "Is there a pre-sale?",
                answer: "The pre-sale commenced on the Pinksale Finance platform on Tuesday 16th November. The presale was set to run for 7-days, however all SQUIRT tokens sold out in less than 24 hours, reaching a hardcap of 640 BNB. For more details, see here: https://www.pinksale.finance/#/launchpad/0xd67B8fCA18E6347fE616fd1e88e7F1fe54Fe87bb?chain=BSC ."
            },
            {
                question: "What was the pre-sale rate?",
                answer: "Pre-sale investors were able to purchase at a rate of 1 BNB = 625,000,000,000 SQUIRT."
            },
            {
                question: "Were pre-sale tokens ‘vested’?",
                answer: "Yes, both for presale investors and the team themselves. Presale investors could claim 25% of their tokens immediately after the pre-sale ended, followed by a further 25% of tokens every 7 days. The team can claim 5% of tokens every 7 days, followed by a further 5% of tokens every 7 days."
            },
            {
                question: "Where can I get squirt?",
                answer: "Squirt can be obtained via PancakeSwap (https://pancakeswap.finance/swap?outputCurrency=0xd2d7289DB68395593D65101753Fec9450ddFB699) DEX or a suitable DEX aggregator service such as 1Inch. We also plan to launch the SQUIRT token on multiple CEX exchanges very soon."
            },
            {
                question: "Are there any benefits to holding (HODL) SQUIRT tokens?",
                answer: "SQUIRT token holders receive yield reflection fees on every buy and sell trade made through Pancake Swap. This is possible due to the 7% transaction tax imposed on buyers and sellers. This 7% tax is distributed as follows: 3% is spilt amongst SQUIRT token holders, 2% goes into the Pancake Swap liquidity pool to gradually increase the price floor of the asset. 2% goes into the team marketing and promotion wallet to allow us to continue to develop the brand, provide liquidity to other exchanges, offer giveaways, and incentivize social media influencers to continue to drive adoption of our products."
            },
            {
                question: "How is this project “rug-proof”?",
                answer: "Please read our detailed Medium article for more information on this topic: https://squirtgame.medium.com/why-squirt-game-is-rug-proof-69555b35874c ."
            },
            {
                question: "How long is the liquidity locked in for the Pancake Swap liquidity pool?",
                answer: "1095 days – Please view the link to verify this: https://www.pinksale.finance/#/pinklock/record/2671?chain=BSC"
            },
            {
                question: "What is a multisig wallet?",
                answer: "Multisig is short for “multi-signature”. A multisig wallet is a special wallet that will only allow withdrawals and transfers of tokens and coins from it, if all parties that form the wallet, have signed the transaction with their own keys. This is a security measure to ensure that no single team member has the power to make transactions with team funds, unless expressly agreed by the team members."
            },
            {
                question: "How many members of the team are there?",
                answer: "Currently we have 8 team members, although we expect this to grow over time."
            },
            {
                question: "Have you been audited?",
                answer: "We are currently undergoing a full audit review from Techrate. Results of the audit will be published before the end of November."
            },
            {
                question: "How many token holders are there?",
                answer: "This information can be viewed here: https://bscscan.com/token/0xd2d7289DB68395593D65101753Fec9450ddFB699#balances ."
            },
            {
                question: "Where are you listed?",
                answer: "We are already listed on CoinMarketCap (https://coinmarketcap.com/currencies/squirt-game/), and expect to be listed on CoinGecko very shortly."
            },
            {
                question: "Are you listed on any exchanges?",
                answer: "We will be making an announcement on this subject very shortly!"
            },
            {
                question: "What is the code?",
                answer: "UP UP DOWN DOWN LEFT RIGHT LEFT RIGHT B A"
            },
        ]
    }
};
