import { observer } from "mobx-react";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from "./index.module.scss";

const Roadmap = observer(() => {
    const { t } = useTranslation();
    const [buttonHovered, setButtonHovered] = useState(false);
    const roadmapArray = t("roadmap.blocks", { returnObjects: true });
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(4);
    const [rightArrowClicked, setRightArrowClicked] = useState(false);
    const [leftArrowClicked, setLeftArrowClicked] = useState(false);
    const [blockClass, setBlockClass] = useState("");

    /*const getBlockClass = () => {
        if (rightArrowClicked) {
            return "roadmap_block";
        } else if (leftArrowClicked) {
            return "roadmap_block";
        } else {
            return "roadmap_block";
        }
    }*/

    return (
        <div id="roadmap" data-aos="zoom-in" data-aos-delay="50" className={styles["roadmap"]}>
            <div className={styles["roadmap__button-wrapper"]}>
                <h2>
                    {t("roadmap.title")}
                </h2>
                <a className={styles["roadmap__button"]} href="https://squirttoken.medium.com/">
                    View Medium
                </a>
                <a className={styles["roadmap__button"]} href="documents/wetpaper.pdf" download>
                    Download Wetpaper
                </a>
            </div>
            <div className={styles["roadmap_wrapper"]}>
                <div className={styles["roadmap_blocks"]}>
                    <img onClick={() => { if (startIndex > 0) { setRightArrowClicked(false); setLeftArrowClicked(true); setStartIndex(startIndex - 1); setEndIndex(endIndex - 1) } }} className={styles["roadmap__line-container-arrow"]} src="images/arrow-left.svg" alt="Left Arrow" />
                    {roadmapArray.map((block, i) => {
                        const descArray = t(`roadmap.blocks.${i}.description`, { returnObjects: true });
                        return (
                            <>
                                {
                                    startIndex <= i && i < endIndex &&
                                    <div key={i} className={styles["roadmap_block"]}>
                                        <div className={styles["roadmap_title"]}>
                                            {block.title}
                                        </div>
                                        <div className={styles["roadmap_description"]}>
                                            <ul>
                                                {descArray.map((desc, i) => {
                                                    return (
                                                        <>
                                                            <li key={i}>{desc}</li>
                                                        </>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </>
                        )
                    })}
                    <img onClick={() => { if (endIndex < roadmapArray.length) { setRightArrowClicked(true); setLeftArrowClicked(false); setStartIndex(startIndex + 1); setEndIndex(endIndex + 1) } }} className={styles["roadmap__line-container-arrow"]} src="images/arrow-right.svg" alt="Left Arrow" />
                </div>
            </div>
        </div>
    )
});

export default Roadmap;
