export const TRANSLATIONS_ES = {
    navbar: {
        home: "INICIO",
        description: "SOBRE",
        buy_nfts: "COMPRAR",
        faq: "PREGUNTAS FRECUENTES",
        browse_squirtgames: "BUSCAR",
        menu: "MENÚ",
    },
    header: {
        title: "¿Qué son los Squirt Games?",
        description: "Se han creado 10000 Squirt Games generados mediante programación para habitar la cadena inteligente de Binance. Cada Squirt Game es un homenaje a los años 90 y a personas/memes queridos por la comunidad dentro de la escena de las criptodivisas. Los Squirt Games también ofrecen posibles recompensas a la comunidad y a las organizaciones benéficas, como se puede ver a continuación.",
        tv_text: ">>> HAGA CLIC EN MI <<<"
    },
    nft_description: {
        reward_title: "Sistema comunitario de recompensas",
        reward_description: "Para crear un incentivo para que se construya una comunidad en torno a Squirt Game, hay un sistema de recompensas que puede ponerse en marcha. Si el suministro total de Squirt Games (10000) se ha vendido en un plazo de 6 meses tras el lanzamiento de la venta, el contrato inteligente tiene una función incorporada que permite entonces a cualquiera iniciar un sistema de recompensa aleatorio con los fondos recaudados a través de la venta de Squirt Games. Se entregan 1000 BNB al propietario de un Squirt Game ID aleatorio, y otros 1000 BNB se dividen entre cien Squirt Game ID aleatorios y sus respectivos propietarios (10 BNB cada uno). Para asegurar una aleatoriedad totalmente verificable y la equidad de los IDs seleccionados como ganadores, Squirt Game ha trabajado con Chainlink para utilizar su VRF Oracle. También se han asignado un total de 300 BNB para ser entregados a una organización benéfica, según la votación de la comunidad. En la votación, cada Squirt Game en posesión de una dirección otorga un voto. En caso de que no se hayan vendido todos los 10000 NFT en un plazo de 6 meses desde el inicio de la venta, los fondos se entregan al equipo. Los fondos no reclamados que queden en el contrato 6 meses desde el inicio de la venta también se reasignarán al equipo.",
        purchase_title: "¿Cómo puedo comprar Squirt Games?",
        purchase_description_1: "La forma más fácil de adquirir tu propio Squirt Game es comprarlo a continuación. También puedes comerciar con ellos en cualquier mercado de NFT que admita Squirt Games. El precio de compra sigue una curva de unión que premia a los primeros seguidores, y el 75% de los NFT cuestan menos de 0,5 BNB mientras que el resto cuesta un máximo de 0,7 BNB.",
        purchase_description_1_prices:
            [
                "0 - 249: Reservado para el marketing, la ayuda y las recompensas comunitarias",
                "250 - 3499: 0.3 BNB",
                "3500 - 5499: 0.4 BNB",
                "5500 - 7499: 0.5 BNB",
                "7500 - 8999: 0.6 BNB",
                "9000 - 9999: 0.7 BNB"
            ],
        purchase_description_2: "Para iniciar la compra, primero debes conectar un monedero que haya sido recargado con BNB. Una vez que haya completado la compra, el NFT se envía automáticamente a su monedero y puede verlo en la pestaña Examinar.",
        interacting_title: "",
        interacting_description: "",
    },
    purchase_nfts: {
        purchase_squirtgame: "Comprar Squirt Games",
        sold: "VENDIDO",
        balance: "Balance:",
        connect_to_wallet: "CONECTAR CON EL MONEDERO",
        purchase_squirtgames: "COMPRAR NFTENDOS",
        wrong_chain_id: "CONECTADO A LA CADENA EQUIVOCADA",
        accept_tos: "DEBES ACEPTAR NUESTRAS TOS",
        disclamer: "Al comprar Squirt Games aceptas que sólo estás comprando un NFT. Las posibles recompensas de la comunidad dependen de que el suministro total de Squirt Games se haya agotado en un plazo de 6 meses desde el inicio de la venta. En el caso de que el suministro total de Squirt Games no se haya vendido en los 6 meses siguientes al inicio de la venta, no se entregarán recompensas comunitarias."
    },
    range_slider: {
        title: "Cantidad de Squirt Games a comprar",
        total_cost: "Coste total"
    },
    nft_search_bar: {
        find_squirtgames: "Buscar Squirt Games:",
        placeholder: "IDs separados por comas, por ejemplo 1,2,3",
        show_nfts: "MOSTRAR MIS NFTS",
        search_nfts: "BUSCAR NFTS"
    },
    faq: {
        title: "PREGUNTAS FRECUENTES",
        items: [
            { question: "¿El sistema de recompensas de la comunidad es totalmente aleatorio?", answer: "Sí. Hemos trabajado con Chainlink y su solución VRF que proporciona una aleatoriedad a prueba de manipulaciones que no puede ser manipulada por ningún usuario, operador de nodo o actor malicioso en comparación con, por ejemplo, el uso de block.time que podría ser manipulado: https://chain.link/solutions/chainlink-vrf" },
            { question: "¿Cuál es el registro de provance de Squirt Game?", answer: "Cada Squirt Game ha sido cifrado usando SHA-256, y todos los hashes se combinan en una cadena larga que es nuevamente cifrada usando SHA-256 y almacenada en la cadena de bloques dentro del contrato inteligente Squirt Game." },
            { question: "¿Aumentará el valor de mis Squirt Games?", answer: "Los Squirt Games son NFTs diseñados como tarjetas comerciales coleccionables en la cadena inteligente de Binance. Al igual que cualquier otra pieza de arte, el precio depende totalmente de lo que la gente esté dispuesta a pagar, y las tarjetas que más valoras pueden tener para otros un valor cero. Al igual que al comprar cualquier pieza de arte, no puedes saber si el precio se mantendrá estable, aumentará o tendrá un valor cero." },
            { question: "¿Puedo saber de antemano qué Squirt Game voy a recibir?", answer: "Antes de que un Squirt Game haya sido acuñado, toda la información, excepto el hash SHA-256 perteneciente a la imagen, no se revela. Una vez realizada la compra, el repositorio de Squirt Game se actualiza automáticamente con la imagen y las estadísticas correspondientes en pocos minutos." },
            { question: "¿Qué ocurre si no se han vendido los 10000 Squirt Games en 6 meses?", answer: "En el caso de que no se hayan vendido todos los Squirt Games en un plazo de 6 meses, la recompensa comunitaria se cancela y todos los fondos recaudados van al equipo." },
            { question: "¿No recibirá Squirt Game nada si los 10000 Squirt Games se han vendido en 6 meses?", answer: "Una vez que el fondo de recompensas de la comunidad (2000 BNB) se ha llenado, Squirt Game puede retirar cualquier exceso de fondos. Por ejemplo, si hay 2100 BNB en el contrato, Squirt Game puede retirar un máximo de 100 BNB durante los primeros 6 meses. Si hay 1900 BNB, Squirt Game no puede retirar ningún fondo del contrato durante los primeros 6 meses. Si no se han vendido todos los 10000 Squirt Games en los 6 meses, entonces el sistema de recompensas de la comunidad no ha tenido éxito, lo que significa que no se pagan las recompensas de la comunidad y Squirt Game puede retirar todos los fondos actualmente en el contrato." },
            { question: "¿Cómo interactúo con mis Squirt Games?", answer: "Puedes ver tus Squirt Games en este sitio web y en cualquier monedero con soporte, y son negociables en los mercados NFT abiertos que soportan el estándar BEP721." },
            { question: "¡He ganado! ¿Cómo reclamo mi recompensa comunitaria?", answer: "Puede reclamar su recompensa comunitaria en el sitio web en un plazo de 6 meses desde el inicio de la venta, si el programa de recompensas comunitarias ha tenido éxito. Para cobrar su recompensa comunitaria, introduzca el número de ranura y pulse el botón Reclamar recompensa. Para reclamar con éxito, la dirección del ganador y la dirección desde la que realiza la transacción deben coincidir." },
            { question: "¿Qué ocurre si hay recompensas comunitarias no reclamadas después de seis meses desde el inicio de la venta?", answer: "Los fondos no reclamados que queden en el contrato 6 meses después del inicio de la venta también pertenecerán al equipo." },
            { question: "¿Cuál es el código?", answer: "Arriba, arriba, abajo, abajo, izquierda, derecha, izquierda, derecha, B, A." }
        ]
    }
};
