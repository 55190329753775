import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import styles from "./index.module.scss";

const Stickers = observer(() => {
    const { t } = useTranslation();

    return (
        <div className={styles["stickers"]}>
            <div data-aos="fade-right" className={styles["stickers__col"]}>
                <div className={styles["stickers__image-wrapper"]} >
                    <img alt="Marketplace" className={styles["stickers__image"]} src={"images/marketplace.png"} />
                </div>
                <div className={styles["stickers__description-wrapper"]}>
                    <img alt="Marketplace Logo" className={styles["stickers__description-logo"]} src={"images/squirt-market.png"} />
                    <div className={styles["stickers__description-text"]}>
                        <span>The wettest</span>
                        <span>NFT Marketplace focused</span>
                        <span>on NSFW content</span>
                    </div>
                </div>
            </div>
            <div data-aos="fade-left" data-aos-delay="300" className={styles["stickers__col"]}>
                <div className={styles["stickers__image-wrapper"]} >
                    <img alt="Squirt Punk" className={styles["stickers__image"]} src={"images/squirtpunk.png"} />
                </div>
                <div className={styles["stickers__description-wrapper"]}>
                    <img alt="Squirt Punk Logo" className={styles["stickers__description-logo"]} src={"images/squirt-punks.png"} />
                    <div className={styles["stickers__description-text"]}>
                        <span>Autogenerated NFTs</span>
                        <span>with randomized</span>
                        <span>SquirtPower:</span>
                        <span>- Voting power</span>
                        <span>- Gov. Staking Power</span>
                    </div>
                </div>
            </div>
            <div data-aos="fade-right" data-aos-delay="600" className={styles["stickers__col"]}>
                <div className={styles["stickers__image-wrapper"]} >
                    <img alt="NSFW" className={styles["stickers__image"]} src={"images/nsfw.png"} />
                </div>
                <div className={styles["stickers__description-wrapper"]}>
                    <img alt="Squirt Squad" className={styles["stickers__description-logo"]} src={"images/squirt-squad.png"} />
                    <div className={styles["stickers__description-text"]}>
                        <span>NSFW NFT collection</span>
                        <span>- dirty artworks with very</span>
                        <span>limited supply</span>
                    </div>
                </div>
            </div>
            <div data-aos="fade-left" data-aos-delay="900" className={styles["stickers__col"]}>
                <div className={styles["stickers__image-wrapper"]} >
                    <img alt="Squirt17" className={styles["stickers__image"]} src={"images/squirt17.png"} />
                </div>
                <div className={styles["stickers__description-wrapper"]}>
                    <img alt="Squirt17 Logo" className={styles["stickers__description-logo"]} src={"images/squirt-17.png"} />
                    <div className={styles["stickers__description-text"]}>
                        <span>On-chain lottery minigame. Play and Win with $SQUIRT</span>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-delay="1200" className={styles["stickers__col"]}>
                <div className={styles["stickers__image-wrapper--large"]} >
                    <img alt="Squirt17" className={styles["stickers__image"]} src={"images/squirt-gun.png"} />
                </div>
                <div className={styles["stickers__description-wrapper--large"]}>
                    <img alt="Squirt17 Logo" className={styles["stickers__description-logo"]} src={"images/squirt-dao.png"} />
                    <div className={styles["stickers__description-text--large"]}>
                        <span>Right place to be for All hardcore squirters.</span>
                        <span>Hold SquirtPunk NFT and $SQUIRT token to participate in projects key strategical decitions and future DAO investments.</span>
                        <span>Stake multiple SquirtPunk NFTs and combine theirs power to receive profit share, limited nfts and other benefits.</span>
                    </div>
                </div>
                <div className={styles["stickers__coming-wrapper--large"]}>
                    <span>CUMMING SOON</span>
                </div>
            </div>
        </div>
    )
});

export default Stickers;
