import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import styles from "./index.module.scss";

const Socials = observer(() => {
    const { t } = useTranslation();
    const [socials, switchSocials] = useState(false);
    const [showImages, setShowImages] = useState(false);

    useEffect(() => {
        if (socials) {
            setTimeout(() => {
                setShowImages(true);
            }, 500);
        } else {
            setShowImages(false);
        }
    }, [switchSocials, socials])

    return (
        <>
            {
                !socials ?
                    <div className={styles["socials__container"]} onClick={() => switchSocials(!socials)}>
                        <div className={styles["socials__text"]} >
                            {t("socials.title")}
                        </div>
                    </div >

                    :
                    <div className={styles["socials__container--open"]} onClick={() => switchSocials(!socials)}>
                        <div className={styles["socials__container--open-wrapper"]}>
                            <div className={styles["socials__text--open"]} >
                                {t("socials.close")}
                            </div>
                            <div className={styles["socials__images-container"]}>
                                {
                                    showImages ?
                                        <>
                                            <a href="https://t.me/squirtgame_token">
                                                <img alt="Telegram icon" className={styles["social-icons"]} src={"images/mail.svg"} />
                                            </a>
                                            <a href="https://t.me/squirtgame_token">
                                                <img alt="Telegram icon" className={styles["social-icons"]} src={"images/telegram.svg"} />
                                            </a>
                                            <a href="https://discord.com/invite/69s3hHBhJf">
                                                <img alt="Discord icon" className={styles["social-icons"]} src={"images/discord.svg"} />
                                            </a>
                                            <a href="https://twitter.com/squirtgametoken">
                                                <img alt="Twitter icon" className={styles["social-icons"]} src={"images/twitter.svg"} />
                                            </a>
                                            <a href="https://www.reddit.com/r/SquirtGameToken/">
                                                <img alt="Reddit icon" className={styles["social-icons"]} src={"images/reddit.svg"} />
                                            </a>
                                            <a href="http://squirttoken.medium.com">
                                                <img alt="Medium icon" className={styles["social-icons"]} src={"images/medium.svg"} />
                                            </a>
                                            <a href="https://coingecko.com">
                                                <img alt="Coingecko icon" className={styles["social-icons"]} src={"images/coingecko.svg"} />
                                            </a>
                                            <a href="https://github.com/squirtgame">
                                                <img alt="Github icon" className={styles["social-icons"]} src={"images/github.svg"} />
                                            </a>
                                            <div className={styles["socials__twitter"]}>
                                                <TwitterTimelineEmbed
                                                    sourceType="profile"
                                                    screenName="squirtgametoken"
                                                    options={{ height: 400 }}
                                                />
                                            </div>
                                        </>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
            }
        </>
    )
});

export default Socials;
