import { observer } from "mobx-react";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Konami from 'react-konami-code';
import styles from "./index.module.scss";
import { useStores } from "../../stores/RootStore";

const Header = observer(() => {
    const { t } = useTranslation();
    const [bananaPicture, setBananaPicture] = useState("images/banana-closed.svg");
    const [bananaClass, setBananaClass] = useState("banana-closed");
    const [apeVaderImage, setApeVaderImage] = useState("images/squirt-lady.png");
    const [moonImage, setMoonImage] = useState("images/vagina.svg");
    const [rocketImage, setRocketImage] = useState("images/rocket.svg");
    const [saberOn, setSaberOn] = useState(false);
    const [dildo, setDildo] = useState("images/banana.svg");
    const { uiStore } = useStores();
    const { setApeVaderBucketImage } = uiStore;

    const easterEgg = () => {
    }

    return (
        <>
            <Konami action={easterEgg} />
            <div id="header" data-aos-delay="50"
                className={styles["component-container"]}>
                <div className={styles["row"]}>
                    <div data-aos="fade-right" data-aos-delay="50" className={styles["column"]}>
                        <div className={styles["header-welcome-wrapper"]}>
                            <img className={styles["logo"]} src={"images/logo.svg"} alt="SafeApe logo" />
                            <div className={styles["welcome-message"]}>MAKING THE BLOCKCHAIN A WETTER PLACE</div>
                            <div className={styles["welcome-description"]}>
                                <span>SQUIRT Is A Rug-Proof Decentralized</span>
                                <span>Adult Entertainment project</span>
                                <span>That'll Get You Wet.</span>
                            </div>
                            <div className={styles["button-wrapper"]}>
                                <a className={styles["ape-in-button"]} href="https://www.pinksale.finance/#/launchpad/0xd67B8fCA18E6347fE616fd1e88e7F1fe54Fe87bb?chain=BSC">
                                    SEE CONTRACT
                                </a>
                                <a className={styles["ape-in-button"]} href="https://pancakeswap.finance/swap?outputCurrency=0xd2d7289DB68395593D65101753Fec9450ddFB699">
                                    TRADE ON PANCAKE
                                </a>
                            </div>
                            <div className={styles["socials-wrapper"]}>
                                <a href="https://t.me/squirtgame_token">
                                    <img alt="Telegram icon" className={styles["social-icons"]} src={"images/telegram.svg"} />
                                </a>
                                <a href="https://discord.com/invite/69s3hHBhJf">
                                    <img alt="Discord icon" className={styles["social-icons"]} src={"images/discord.svg"} />
                                </a>
                                <a href="https://twitter.com/squirtgametoken">
                                    <img alt="Twitter icon" className={styles["social-icons"]} src={"images/twitter.svg"} />
                                </a>
                                <a href="https://squirttoken.medium.com/">
                                    <img alt="Medium icon" className={styles["social-icons"]} src={"images/medium.svg"} />
                                </a>
                                <a href="https://www.reddit.com/r/SquirtGameToken/">
                                    <img alt="Reddit icon" className={styles["social-icons"]} src={"images/reddit.svg"} />
                                </a>
                                <a href="https://github.com/squirtgame">
                                    <img alt="Github icon" className={styles["social-icons"]} src={"images/github.svg"} />
                                </a>
                                <a href="https://coingecko.com">
                                    <img alt="Coingecko icon" className={styles["social-icons"]} src={"images/coingecko.svg"} />
                                </a>
                                <a href="mailto:squirtgame@email.com">
                                    <img alt="Telegram icon" className={styles["social-icons"]} src={"images/mail.svg"} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-left" data-aos-delay="50" className={styles["column"]}>
                        <div className={styles["squirt-wrapper"]}>
                            <img className={styles["squirt-lady"]} src={apeVaderImage} alt="Squirt Lady" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default Header;
